import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import BmPageDark from "src/components/bmPageDark"
import Btf from "src/components/btf"
import Disclosure from "@tightrope/disclaimers"
import RawFooter from "@tightrope/footerlinks/rawfooter.js"
import styles from "./styles.module.scss"

const BmData = import("./data/data.json");
const btfData = import("./data/btfData.json");

const footer = <RawFooter></RawFooter>

const FeatureContent = () => (
  <div className="features-section">
    <h2 className="features-title">Why Choose Aioli Sous Chef?</h2>
    <div className="features-list">
      <div className="features-column">
        <h3>Convenience</h3>
        <p>Simplify your cooking with our AI Recipe Assistant and access quick, easy recipes right at your fingertips.</p>
        
        <h3>Personalization</h3>
        <p>Customize recipes based on your specific diet and preferences.</p>
      </div>
      
      <div className="features-column">
        <h3>Inspiration</h3>
        <p>Discover new, exciting recipes every day, so you'll never run out of meal ideas again.</p>
        
        <h3>Confidence</h3>
        <p>Cook with ease and confidence using our smart assistant.</p>
      </div>
    </div>
    <div className="features-outro">
      Say goodbye to recipe struggles and cooking stress. Let Aioli Sous Chef solve your common kitchen challenges!
    </div>
  </div>
);

export default function prdbest() {
  return(
    <HomepageLayout>
      <Helmet>
        <title>Aioli Chef Features | aiolisouschef.com</title>
      </Helmet>
      <div className={styles.pageWrapper}>
        <section>
          <BmPageDark data={BmData}>
            <Disclosure></Disclosure>
          </BmPageDark>
        </section>
        <FeatureContent />
        <Btf data={btfData} footer={footer}>
          <Disclosure language='en-mbc-edge'></Disclosure>
        </Btf>
      </div>
    </HomepageLayout>
  )
}
